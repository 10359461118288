import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from 'react';

const OpenIcon = () => {
    return (
        <FeatherIcon icon="calendar" size={20} />
    );
};

// the form input only accepts one format
const DateTimeField = ({
    serializerField: { attrs, type: fieldType, name },
    value,
    errors,
    inputProps,
    handleValuesChange,
    handleRemoveErrors,
    ...props
}) => {
    const [displayValue, setDisplayValue] = useState('');
    
    useEffect(() => {
      if (attrs.current_value) {
        setDisplayValue(attrs.current_value)
      }
    }, [attrs.current_value])
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                components={{ OpenPickerIcon: OpenIcon }}
                label={attrs['label']}
                value={displayValue || ''}
                inputFormat="dd-MM-yyyy HH:mm:ss"
                ampm={false}
                inputProps={{ ...inputProps }}

                onChange={(value, keyboardInputValue) => {
                    if (keyboardInputValue !== undefined) {
                        setDisplayValue(keyboardInputValue);
                    } else {
                        setDisplayValue(value);
                    }
                    handleValuesChange({ value, keyboardInputValue }, name, { name, type: fieldType, attrs })
                }}

                renderInput={(params) => {
                    return (
                    <TextField
                        {...params}
                        {...props}
                        inputProps={{
                            ...params.inputProps,
                            // placeholder: attrs['help_text'] || ''
                            placeholder: ''
                        }}
                        error={errors.length > 0}
                        onClick={event => handleRemoveErrors(event, name)}
                        helperText={errors.length > 0
                            ? errors.join('.\n')
                            : attrs['help_text'] || attrs.input_formats[0] || ''}
                    />
                )
                }}
            />
        </LocalizationProvider>
    );
};

export default DateTimeField;


let a = {
    "type": "DateTimeField",
    "name": "start_time",
    "attrs": {
        "read_only": false,
        "write_only": false,
        "required": true,
        "default": null,
        "allow_blank": null,
        "allow_null": false,
        "style": {},
        "label": "Thời gian bắt đầu",
        "help_text": null,
        "initial": null,
        "format": null,
        "input_formats": [
            "YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]"
        ]
    }
}

let b = {
    "type": "DateTimeField",
    "name": "start_time",
    "attrs": {
        "read_only": false,
        "write_only": false,
        "required": true,
        "default": null,
        "allow_blank": null,
        "allow_null": false,
        "style": {},
        "label": "Thời gian bắt đầu",
        "help_text": null,
        "initial": null,
        "format": null,
        "input_formats": [
            "dd-mm-YYYY %H:%M:%S"
        ]
    }
}