import ErrorMessage from '../ErrorMessage';


const NetworkError = () => {
    return (
        <ErrorMessage
            code=""
            title="Lỗi mạng."
            message="Trang web không thể truy cập vào máy chủ, điều này xảy ra do kết nối của bạn kém hoặc máy chủ không hoạt động."
            action="Tải lại"
            onClick={() => {
                window.location.reload();
            }}
        />
    );
};

export default NetworkError;