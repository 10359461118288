import {styled} from "@mui/system";
// import {Button} from "@mui/material";
import { LoadingButton } from '@mui/lab';

const PrimaryGradientButton = styled(LoadingButton)(({theme}) => ({
    // background: theme.palette.primaryGradient.main,
    // background: "linear-gradient(#FFBC65, #FF8A65)",
    background: theme.customPalette.primary.main,
    height: '35px',
    boxShadow: theme.shadows[0],
    padding: theme.spacing(5),
    borderRadius: '12px',
}));


export default PrimaryGradientButton;