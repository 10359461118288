import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, FormHelperText, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import ReactLightbox from 'yet-another-react-lightbox'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export default function InputFileUpload({
  serializerField: { attrs, type: fieldType, name },
  errors,
  value,
  fieldInputValue,
  inputProps,
  handleValuesChange,
  handleRemoveErrors,
  ...props
}) {
  const [openLightbox, setOpenLightbox] = React.useState(false)
  const [fileUrl, setFileUrl] = useState('')
  useEffect(() => {
    if (typeof value === 'string') {
      setFileUrl(value)
    }
  }, [value])

  return (
    <>
      <Stack {...props}>
        <Stack spacing={2}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
            sx={{
              width: 1,
              maxWidth: 'fit-content',
              borderRadius: 1,
              transition: '.3s',
              overflow: 'hidden',
              position: 'relative',
              cursor: 'pointer',
              ...(fileUrl
                ? {
                    border: '1px dashed #ccc',
                    height: 200,
                    p: 2
                  }
                : {
                    height: 0,
                    p: 0
                  }),
              '&:hover': {
                '& .--view-overlay': {
                  opacity: 1
                }
              }
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              height={1}
              sx={{ gap: 2 }}
            >
              <Box
                sx={{
                  height: 1,
                  aspectRatio: '1/1',
                  borderRadius: 0.5,
                  overflow: 'hidden'
                }}
                flexShrink={0}
              >
                <Box
                  component='img'
                  src={fileUrl}
                  sx={{ width: 1, height: 1, objectFit: 'cover' }}
                />

                <Stack
                  component='a'
                  // target='_blank'
                  // href={fileUrl}

                  onClick={() => setOpenLightbox(true)}
                  className='--view-overlay'
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    background: 'rgba(0, 0, 0, 0.5)',
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    color: (theme) => theme.palette.common.white,
                    transition: '.3s',
                    opacity: 0,
                    textDecoration: 'none'
                  }}
                >
                  Xem {attrs?.label}
                </Stack>
              </Box>
            </Stack>
          </Stack>
          <Button
            component='label'
            onClick={(event) => handleRemoveErrors(event, name)}
            role={undefined}
            variant='contained'
            sx={{
              width: 'fit-content',
              textTransform: 'initial',
              background: (theme) => theme.customPalette.primary.main
            }}
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {fileUrl ? 'Thay đổi ' : 'Tải '} {attrs?.label?.toLowerCase()}
            <VisuallyHiddenInput
              type='file'
              max={1}
              onChange={(ev) => {
                if (ev.target.files[0]) {
                  const file = ev.target.files[0]
                  const urlFile = URL.createObjectURL(file)
                  if (!file) {
                    // onRemove()
                  } else {
                    setFileUrl(urlFile)
                    handleValuesChange(file, name, {
                      name,
                      type: fieldType,
                      attrs
                    })
                  }
                }
              }}
            />
          </Button>
        </Stack>

        <FormHelperText sx={{
          color: theme => errors.length > 0 ? theme.palette.error.main : 'inherit',
          m: '3px 14px 0'
        }}>{errors.length > 0 ? errors.join('.\n') : attrs['help_text'] || ''}</FormHelperText>
      </Stack>
      <ReactLightbox
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        controller={{ closeOnBackdropClick: true }}
        slides={[
            { src: fileUrl },
        ]}
        carousel={{ finite: true }}
      />
    </>
  )
}
