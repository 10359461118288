import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material'
import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

const ChoiceField = ({
  serializerField: { attrs, type, name },
  errors,
  value,
  inputProps,
  handleValuesChange,
  handleRemoveErrors,
  ...props
}) => {
  const id = `id_${name}`

  const [options, setOptions] = useState([])

  const [isSelectAll, setIsSelectAll] = useState(false)

  const [numberTags, setNumberTags] = useState(10)

  const isMultiSelect = useMemo(() => {
    return attrs?.['is_multiple']
  }, [attrs])

  let configValue = useMemo(() => {
    if (isMultiSelect) {
      if (typeof value === 'string' || typeof value === 'number') {
        let targetValue
        if (isMultiSelect) {
          targetValue = [{ label: attrs['choices'][value], value }]
        } else {
          targetValue = { label: attrs['choices'][value], value }
        }
        return targetValue
      } else if (!value) {
        return []
      } else {
        if (typeof value?.[0] != 'object') {
          let targetValue = []
          value.forEach((item) => {
            targetValue.push({ label: attrs['choices'][item], value: item })
          })
          return targetValue
        }
        return value
      }
    } else {
      if (value) return value
      return ''
    }
  }, [value, type, options, isMultiSelect])

  const onViewAll = useCallback((numTags) => {
    setNumberTags(numTags)
  }, [])

  useEffect(() => {
    if (attrs['choices']) {
      setOptions((prev) => {
        const clonePrev = cloneDeep(prev)
        for (let [value, label] of Object.entries(attrs['choices'])) {
          clonePrev.push({ label, value })
        }
        return clonePrev
      })
    }
  }, [attrs['choices']])

  useEffect(() => {
    if (
      configValue?.length > 0 &&
      configValue?.length === options?.length &&
      !isSelectAll
    ) {
      setIsSelectAll(true)
    }
  }, [configValue, options, isSelectAll])

  return (
    <FormControl {...props} error={errors.length > 0}>
      <Autocomplete
        id={id}
        multiple={isMultiSelect}
        disableCloseOnSelect={isMultiSelect}
        isOptionEqualToValue={
          isMultiSelect
            ? (option, value) => option.value == value.value
            : undefined
        }
        value={configValue}
        filterOptions={(options, params) => {
          const filter = createFilterOptions()
          const filtered = filter(options, params)
          if (isMultiSelect && !isSelectAll)
            return [
              { label: 'Chọn tất cả...', value: 'select-all' },
              ...filtered
            ]
          return [...filtered]
        }}
        getOptionLabel={(option) => {
          if (option.label) return option.label
          let configOption = options.find((o) => o?.value == option)
          return configOption?.label || ''
        }}
        options={options}
        onChange={(event, option) => {
          console.log('zzz event', event)
          console.log('zzz option', option)
          let configOption

          if (isMultiSelect) {
            configOption = option
          } else {
            configOption = option?.value || null
          }

          if (
            Array.isArray(option) &&
            option.find((it) => it?.value === 'select-all')
          ) {
            configOption = options
            setIsSelectAll(true)
          }

          if (isSelectAll) setIsSelectAll(false)

          const configEvent = {
            target: {
              value: configOption
            }
          }

          handleValuesChange(configEvent, name)
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.value}>
              {isMultiSelect &&
              option.value === 'select-all' &&
              !isSelectAll ? (
                <Box
                  component='label'
                  sx={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  Chọn tất cả
                </Box>
              ) : (
                option.label
              )}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={attrs['label']}
            onClick={(event) => handleRemoveErrors(event, name)}
          />
        )}
        renderTags={(value, getTagProps) => {
          const numTags = value.length
          // const limitTags = 10;
          const limitTags = numberTags

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={option.label}
                />
              ))}

              {/* {numTags > limitTags && ` +${numTags - limitTags}`} */}
              {numTags > limitTags && (
                <Chip label={`+${numTags - limitTags} (hiển thị tất cả)`} onClick={() => onViewAll(numTags)} color='info' variant='outlined' size='small'/>
              )}
            </>
          )
        }}
      />

      {attrs['help_text'] || errors.length > 0 ? (
        <FormHelperText>
          {errors.length > 0 ? errors.join('.\n') : attrs['help_text']}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default ChoiceField
