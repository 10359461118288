import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Collapse, Paper, Typography } from "@mui/material";
import { styled } from '@mui/system';
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// import AdminLogo from "../../../../assets/logo/AdminLogoLightFilled";
import AdminLogo from "../../../../assets/logo/logo120.png";
import { fetchCsrfToken, setUser } from "../../../authentication/AuthProvider";
import DynamicField from "../../../forms/fields/DynamicField";

const PaperBackground = styled(Paper)(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    borderRadius: "8px",

    width: "360px",

    [theme.breakpoints.down('sm')]: {
        width: "350px",
    },

    "&::after": {
        content: '""',
        position: "absolute",
        // background: theme.palette.primaryGradient.mainHorizontal,
        // background: "linear-gradient(0.25turn, #FFBC65, #FF8A65)",
        background: theme.customPalette.primary.mainHorizontal,
        width: "100%",
        top: "0px",
        height: "8px",
        borderRadius: "8px 8px 0 0",
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const HelperText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5), color: theme.palette.text.secondary,
}));

const FeedBackTitle = styled(AlertTitle)(({ theme }) => ({
    color: theme.palette.error['dark'],
}));

const Form = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    ".MuiTextField-root:nth-of-type(1)": {
        marginTop: theme.spacing(5),
    }
}));

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
    marginTop: theme.spacing(5),
    padding: theme.spacing(4),
    // background: theme.palette.primaryGradient.main,
    // background: "linear-gradient(#FFBC65, #FF8A65)",
    background: theme.customPalette.primary.main,
    borderRadius: "8px",
    boxShadow: theme.shadows[0],
    "&:hover": {
        boxShadow: theme.shadows[0],
    }
}));

const Logo = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(7),
}));

const InputComponent = styled(DynamicField)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const LoginForm = ({ status, submitStatus, serializerFields, handleFormSubmit, handleRemoveNonFieldErrors, nonFieldErrors, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [responseStatus, setResponseStatus] = useState('');

    const [loading, setLoading] = useState(false)
    return (<PaperBackground elevation={0}>
        {/* <Logo>
            <AdminLogo aria-label="Bolt logo" className='admin-logo' width='50px' height='50px' />
        </Logo> */}


        <Box sx={{ width: '50px', height: '50px', marginBottom: theme => theme.spacing(7) }}>
            <Box
              component='img'
              src={AdminLogo}
              sx={{
                width: 1,
                height: 1,
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            ></Box>
          </Box>


        <Title variant="h3">C'Choi Dashboard</Title>
        <HelperText variant="body2">Nhập thông tin bên dưới để tiếp tục</HelperText>

        <Collapse in={!!responseStatus} sx={{ width: '100%' }}>
            <Alert
                severity="error"
                icon={<FeatherIcon icon="alert-circle" size={20} />}
                // onClose={event => handleRemoveNonFieldErrors()}
                onClose={event => setResponseStatus('')}
            >
                <FeedBackTitle><Typography
                    variant="h6">{responseStatus}</Typography></FeedBackTitle>
                
                {/* <FeedBackTitle><Typography
                    variant="h6">{responseStatus ? responseStatus : ''}</Typography></FeedBackTitle>
                <Typography
                    variant="body1">{nonFieldErrors ? nonFieldErrors.join('\n') : ''}</Typography> */}
            </Alert>
        </Collapse>

        <Form onSubmit={event => {
            handleRemoveNonFieldErrors();
            setLoading(true)
            handleFormSubmit(event).then(response => {
                setResponseStatus(response.status);
                if (response.status === 200) {
                    // if successfully authenticated save the user
                    dispatch(setUser(response.data['user']));
                    // refresh csrfToken
                    dispatch(fetchCsrfToken());
                    // then redirect to the route the client came from
                    if (location.state?.from) {
                        navigate(location.state.from);
                    } else {
                        navigate('/', { replace: true });
                    }
                }
            }).catch(error => {
                setResponseStatus(error?.response?.data?.message || error?.response?.message || error?.message || 'Lỗi')
            }).finally(() => {
                setLoading(false)
            });
        }}>
            {
                serializerFields.map((serializerField) => {
                    return (
                        <InputComponent
                            key={serializerField.name}
                            serializerField={serializerField}
                            {...props}
                        />
                    )
                })
            }

            <SubmitButton
                loadingPosition='start'
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary">
                Đăng nhập
            </SubmitButton>
        </Form>
    </PaperBackground>);
}

export default LoginForm;
