import client from '../../application/client.js';

class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.client = client;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                const formData = new FormData()
                formData.append('file', file)

                return client.post("/upload/", formData).then(res => {
                    resolve({
                        default: res.data.data.file
                    })
                }).catch(() => {
                    reject(`Không thể upload file: ${ file.name }.`)
                })
            } ) );
    }

   
}

// ...

export default function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
    };
}