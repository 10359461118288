import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ActivityHeader from "./ActivityHeader";
import { resetActivityState } from "./activityPageSlice";
import ActivityTable from "./ActivityTable";
import ActivityTableV2 from './ActivityTable/ActivityTableV2.js';

const ActivityPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetActivityState());
        };
    }, []);

    return (
        <Box>
            <ActivityHeader />
            {/* <ActivityTable /> */}
            <ActivityTableV2 />
        </Box>
    )
};

export default ActivityPage;