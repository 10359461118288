export const renderRowsPerPageOptions = (full_count, limit = 8) => {
  if (!full_count) return [limit, 200]

  if (full_count == limit) return [limit]

  if (full_count < limit) return [full_count, limit]
  
  if (full_count > limit) return [limit, full_count]
}
export const onKeyDownInputTypeNumber = (evt) =>
  ['e', 'E', '+', '-', '.', ','].includes(evt.key) && evt.preventDefault()