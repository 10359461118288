import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { selectModelByName } from "../IndexPage";
import ChangeListHeader from './ChangeListHeader';
import ChangeListTable, { resetChangeList } from "./ChangeListTable";
import ChangeListTableV2 from './ChangeListTable/ChangeListTableV2.js';

const ChangeListPage = () => {
    const { appLabel, modelName } = useParams();
    const model = useSelector(state => selectModelByName(state, appLabel, modelName));
    const dispatch = useDispatch();
    // when the changelist page unmounts
    // set the changelist table status to empty
    useEffect(() => {
        return () => {
            dispatch(resetChangeList());
        };
    }, [modelName]);

    return (
        <Box sx={{ marginBottom: theme => theme.spacing(7) }}>
            <ChangeListHeader
                model={model}
            />
            {/* <ChangeListTable
                model={model}
            /> */}
            <ChangeListTableV2
                model={model}
            />
        </Box>
    );
};

export default ChangeListPage;
