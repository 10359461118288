import ErrorMessage from '../ErrorMessage';
import { useNavigate } from 'react-router-dom';

const NotFoundError = () => {
    const navigate = useNavigate();

    return (
        <ErrorMessage
            code="404"
            title="Không tìm thấy trang."
            message="Trang bạn đang tìm kiếm không tồn tại hoặc đã bị di chuyển, hãy thử một url khác hoặc quay lại trang chủ."
            action="Trở về trang chủ"
            onClick={() => {
                navigate('/')
            }}
        />
    );
};

export default NotFoundError;