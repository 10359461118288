// @ts-nocheck
import {
  Box,
  IconButton,
  imageListClasses,
  InputAdornment,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { renderRowsPerPageOptions } from '../../../../utils/helpers.js'
import {
  fetchChangelistData,
  selectChangeListData,
  setFilters,
  setPage,
  setPageSize,
  setSelectionModel,
  selectedAction,
  selectionModel,
  selectAcross,
  setSelectAcross,
  performAction
} from './changeListSlice.js'
import TableLoadingSkeleton from './TableLoadingSkeleton/TableLoadingSkeleton.js'
import SearchIcon from '@mui/icons-material/Search'
import { Icon } from '@iconify/react'
import TableToolbar from './TableToolbar/TableToolbar.js'
import debounce from 'lodash.debounce'
import { selectCsrfToken } from '../../../authentication/AuthProvider'
import { overText } from '../../../../application/css.js'

export default function ChangeListTableV2({ model }) {
  const dispatch = useDispatch()

  const theme = useTheme()

  const [selectedAction, setSelectedAction] = useState('')

  const location = useLocation()

  const canUpdate = useMemo(() => model?.perms?.change , [model])

  const [skeletonAnimationDelay, setSkeletonAnimationDelay] = useState(true)

  const csrfToken = useSelector(selectCsrfToken)

  const { appLabel, modelName } = useParams()

  const [rowSelection, setRowSelection] = useState({})

  const {
    status,
    rowsStatus,

    columns: xColumns,
    rows,
    config,

    page,
    pageSize,

    selectionModel,
    selectAcross,

    filters,
    sorting,

    alertInfo
  } = useSelector(selectChangeListData)

  // ----------------- ACTIONS
  const handleSelectionModelChange = (newSelectionModel) =>
    dispatch(setSelectionModel(newSelectionModel))

  const handleActionSelectChange = (event) =>
    setSelectedAction(event.target.value)

  const handleToggleSelectAcross = (event) => {
    event.preventDefault()
    if (selectAcross) {
      dispatch(setSelectionModel([]))
    }
    dispatch(setSelectAcross(!selectAcross))
  }

  const handlePerformAction = () =>
    (async () => {
      setRowSelection({})

      dispatch(
        performAction({
          url: model.perform_action_url.replace('http:', 'https:'),
          action: selectedAction,
          selectedIds: selectionModel,
          selectAcross: selectAcross,
          csrfToken: csrfToken
        })
      )
    })()

  const handleFiltersChange = useCallback(
    (event, name) => {
      dispatch(setFilters({ ...filters, [name]: event.target.value }))
    },
    [filters]
  )

  const handleSearchFilterChange = useCallback(
    debounce((value, name) => {
      dispatch(setFilters({ ...filters, [name]: value }))
    }, 800),
    [filters['filter_by_search']]
  )
  // ----------------- END ACTIONS
  const columns = useMemo(() => {
    if (!xColumns) {
      return []
    }
    return xColumns?.map((col) => {
      return {
        accessorKey: col.field,
        header: col.headerName,
        muiTableHeadCellProps: {
          align: col.headerAlign
        },
        minSize: col.minWidth,
        grow: 1,
        Cell: col.renderCell
          ? col.renderCell
          : (props) => {
              return <div>{props.renderedCellValue}</div>
            }
      }
    })
  }, [xColumns])

  const table = useMaterialReactTable({
    // autoResetPageIndex: false,
    columns,
    data: rows,
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: true,
    layoutMode: 'grid',
    enablePagination: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,

    state: {
      columnOrder: [
        'mrt-row-select',
        'mrt-row-numbers',
        ...columns?.map((col) => col.accessorKey),
        'mrt-row-actions'
      ],
      isLoading: rowsStatus === 'notUpdated',
      rowSelection
    },

    displayColumnDefOptions: {
      'mrt-row-numbers': {
        size: 60,
        muiTableHeadCellProps: {
          sx: {
            textAlign: 'center'
          }
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      'mrt-row-actions': {
        grow: true,
        muiTableBodyCellProps: {
          align: 'center'
        }
      }
    },

    // SHOW INDEX
    enableRowNumbers: true,
    rowNumberDisplayMode: 'static',

    // DROPDOWN ACTIONS MENU
    enableRowActions: canUpdate,
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        // disabled={modelName === 'notification' || modelName === 'servicecode'}
        key='edit'
        sx={{ minWidth: 100 }}
        component={RouterLink}
        to={`/${appLabel}/${modelName.toLowerCase()}/${
          row.original.id
        }/change/`}
        onClick={() => {
          closeMenu()
        }}
      >
        <Typography
          sx={{ width: 1 }}
          textAlign='center'
          variant='caption'
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          Cập nhật
        </Typography>
      </MenuItem>
      // <MenuItem
      //   key='delete'
      //   sx={{ minWidth: 100 }}
      //   onClick={async () => {
      //     let id = row.original.id
      //     closeMenu()
      //     const res = await client.delete(`user/customuser/${id}/delete/`)
      //     dispatch(
      //       fetchChangelistData({
      //         // url: `/${appLabel}/${modelName.slice(0, -1).toLowerCase()}/changelist/`,
      //         url: `/${appLabel}/${modelName.toLowerCase()}/changelist/`,
      //         page: page + 1,
      //         all: pageSize === config.list_max_show_all
      //       })
      //     )
      //   }}
      // >
      //   <Typography
      //     sx={{ width: 1, color: (theme) => theme.palette.error.main }}
      //     textAlign='center'
      //     variant='caption'
      //     fontWeight={(theme) => theme.typography.fontWeightBold}
      //   >
      //     Xóa
      //   </Typography>
      // </MenuItem>
    ],

    // COMMON
    renderEmptyRowsFallback: () => (
      <Stack py={2} spacing={3} alignItems='center' justifyContent='center'>
        <Stack spacing={1} sx={{ textAlign: 'center' }}>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Không có
            <Box
              component='span'
              sx={{
                mx: 0.5,
                color: 'primary.main'
              }}
            >
              {' '}
              dữ liệu
            </Box>
          </Typography>
        </Stack>
      </Stack>
    ),
    muiTableHeadProps: {
      sx: {
        '& .MuiTableRow-root': {
          color: (theme) => theme.palette.common.white,
          background: (theme) => theme.palette.primary.main,
          ...theme.typography.subtitle1,
          '& *': {
            fontWeight: 'inherit',
            color: (theme) => theme.palette.common.white
          }
        }
      }
    },
    muiTableBodyCellProps: {
      sx: {
        ...theme.typography.subtitle2,
        '& div': {
          ...overText(2)
        }
      }
    },

    localization: {
      move: 'Sắp xếp',
      rowsPerPage: 'Số lượng hiển thị:',
      of: 'trên',
      actions: '',
      rowNumber: 'STT'
    }
  })

  useEffect(() => {
    if (typeof page === 'number') {
      // if the table is empty fetch the table data.
      if (status === 'idle') {
        dispatch(
          fetchChangelistData({
            url: `/${appLabel}/${modelName.toLowerCase()}/changelist/`,
            page: page + 1,
            all: pageSize === config.list_max_show_all
          })
        )
      }

      // when the data is fetched:
      if (status === 'success') {
        // update rows if rows are not updated
        if (rowsStatus === 'notUpdated') {
          dispatch(
            fetchChangelistData({
              url: `/${appLabel}/${modelName.toLowerCase()}/changelist/`,
              page: page + 1,
              all: pageSize === config.list_max_show_all,
              rowsOnly: true,
              filters: filters,
              sorting: sorting
            })
          )
        }

        // when selectAcross is selected select all rows
        if (selectAcross) {
          let rowsIds = rows.map((row) => row.id)
          dispatch(setSelectionModel(rowsIds))
        }
      }
    }
  }, [rowsStatus, selectAcross])

  useEffect(() => {
    if (Object.keys(rowSelection).length) {
      let _indexes = Object.keys(rowSelection)
      let selectedList = rows.filter((row, index) => {
        return _indexes.includes(String(index))
      })
      let selectedListIds = selectedList.map((row) => row.id)
      handleSelectionModelChange(selectedListIds)
    } else {
      handleSelectionModelChange([])
    }
  }, [rowSelection])

  setTimeout(() => {
    setSkeletonAnimationDelay(false)
  }, 1000)
  return (
    <Box sx={{ width: '100%', height: { xs: '600px', md: '82vh' } }}>
      <Box sx={{ height: '100%' }}>
        {status === 'loading' || status === 'idle' || skeletonAnimationDelay ? (
          <TableLoadingSkeleton />
        ) : (
          <Stack>
            <TableToolbar
              {...{
                modelName: model?.name,
                counter: config.result_count,
                filters_list: config.filters,
                selection_counter: config.actions_selection_counter,
                searchFields: config.search_fields,
                actions: config.action_choices,
                selectedAction,
                selectionModel,
                selectAcross,
                filters,
                handleToggleSelectAcross,
                handleActionSelectChange,
                handleFiltersChange,
                handleSearchFilterChange,
                handlePerformAction
              }}
            />
            <MaterialReactTable table={table} />
            <TablePagination
              component='div'
              count={config.result_count}
              page={page}
              rowsPerPage={pageSize}
              // rowsPerPageOptions={[config.list_per_page, config.list_max_show_all]}
              rowsPerPageOptions={renderRowsPerPageOptions(
                config.list_max_show_all,
                config.list_per_page
              )}
              onPageChange={(event, value) => {
                dispatch(setPage(value))
              }}
              onRowsPerPageChange={(event) => {
                dispatch(setPageSize(event.target.value))
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return 'Từ ' + from + ' đến ' + to + ' trên ' + count
              }}
              labelRowsPerPage='Số lượng hiển thị:'
            />
          </Stack>
        )}
      </Box>
    </Box>
  )
}
