import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { Box, FormHelperText, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MyCustomUploadAdapterPlugin from '../../../cke-custom-upload-adaptor/index.js'

const EditorField = ({
  serializerField: { attrs, type: fieldType, name },
  type = null,
  errors,
  value,
  inputProps,
  handleValuesChange,
  handleRemoveErrors,
  ...props
}) => {
  return (
    <Stack {...props}>
      <Typography component='label' ml='14px' color='text.secondary'>{attrs['label']}</Typography>
      <Box
        sx={{
          '& .ck-editor__editable': {
            minHeight: '200px !important'
          }
        }}
        onClick={event => handleRemoveErrors(event, name)}
      >
        <CKEditor
          editor={Editor}
          data={value || ''}
          onChange={(event, editor) => {
            let configEvent = {
              target: {
                value: editor.getData()
              }
            }
            handleValuesChange(configEvent, name)
          }}

          config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
          // config={{
          //   extraPlugins: [MyCustomUploadAdapterPlugin],
          //   toolbar: [ 'heading', '|', 'bold', 'alignment', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload' ]
          // }}
        />
      </Box>
      <FormHelperText sx={{
        color: theme => errors.length > 0 ? theme.palette.error.main : 'inherit',
        m: '3px 14px 0'
      }}>{errors.length > 0 ? errors.join('.\n') : attrs['help_text'] || ''}</FormHelperText>
    </Stack>
  )
}

export default EditorField
