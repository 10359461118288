import axios from "axios";
import { toast } from 'react-toastify';
import config from "./config";

let client = axios.create({
    baseURL: config.url.href,
    // timeout: 10000,
    withCredentials: true,
});

client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => Promise.reject(error)
  )

client.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if(error?.response?.data?.error_type !== 'LOGIN' && 
        error?.response?.data?.error_type !== 'INVALID_FORM' &&
        error?.response?.status !== 403 && 
        error?.code !== 'ERR_NETWORK'){
          toast.error(error?.response?.data?.message || error?.response?.message || 'Lỗi')
        }

        if(error?.response?.data?.errors?.non_field_errors?.[0]){
          toast.error(error.response.data.errors.non_field_errors[0])
        }

        if(error?.response?.status === 401 || error?.response?.status === 403){
          localStorage.removeItem('token')
        }
        return Promise.reject(error);
    }
);

export default client
