// @ts-nocheck
import {
  Box,
  Chip,
  Stack,
  Typography,
  TablePagination,
  Divider,
  useTheme
} from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { renderRowsPerPageOptions } from '../../../../utils/helpers.js'
import {
  fetchActivityLog,
  resetActivityState,
  selectActivityData,
  setPage,
  setPageSize
} from '../activityPageSlice.js'
import TableSkeleton from './TableSkeleton/TableSkeleton.js'

const closeAnimation = (setSkeletonAnimationDelay) => {
  setTimeout(() => {
    setSkeletonAnimationDelay(false)
  }, 1000)
}

export default function ActivityTableV2() {
  const dispatch = useDispatch()

  const theme = useTheme()

  const location = useLocation()

  const [skeletonAnimationDelay, setSkeletonAnimationDelay] = useState(true)

  const { objectId = null } = useParams()

  const { status, rowsStatus, rows, config, page, pageSize } =
    useSelector(selectActivityData)
  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'stt',
        header: 'STT',
        minSize: 100,
        grow: false,
        muiTableHeadCellProps: {
          align: 'center'
        },
        Cell({ row }) {
          return (
            <Typography component='div' variant='caption' sx={{ fontWeight: theme => theme.typography.fontWeightBold, textAlign: 'center', width: 1 }}>
              {row.original.stt}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'user',
        header: 'Thông tin user',
        minSize: 150,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell({ row }) {
          let { full_name, phone_number, role } = row.original.user
          return (
            <Stack spacing={2}>
              <Chip size='small' label={String(role).toUpperCase()} variant='filled' color='default'/>
              <Stack alignItems='center'>
                <Typography variant='button' component='span'>{full_name}</Typography>
                <Typography variant='subtitle2'>{phone_number}</Typography>
              </Stack>
            </Stack>
          )
        }
      },
      {
        accessorKey: 'object_id',
        header: 'Object Id',
        minSize: 150,
        Cell({ row }) {
          return <Typography variant='subtitle2' sx={{ wordBreak: 'break-all' }}>{row.original.object_id}</Typography>
        }
      },
      {
        accessorKey: 'action',
        header: 'Hành động',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        minSize: 200,
        Cell({ row }) {
          let label =
            row.original.action === 'changed' ? 'Đã thay đổi' : 'Đã thêm'
          return (
            <Chip
              size='small'
              label={label}
              color={row.original.action === 'changed' ? 'success' : 'warning'}
              sx={{
                fontWeight: theme => theme.typography.fontWeightBold,
                color: '#fff'
              }}
            />
          )
        }
      },
      {
        accessorKey: 'time',
        header: 'Thời gian',
        grow: true, //allow this column to grow to fill in remaining space - new in v2.8
      }
    ]
  }, [])

  const table = useMaterialReactTable({
    // autoResetPageIndex: false,
    columns,
    data: rows,
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: true,
    layoutMode: 'grid',
    enablePagination: false,
    
    state: {
      isLoading: rowsStatus === 'notUpdated'
    },
    // COMMON
    renderEmptyRowsFallback: () => (
      <Stack py={2} spacing={3} alignItems='center' justifyContent='center'>
        {/* <UploadIllustration sx={{ width: 1, maxWidth: 200 }} /> */}
        <Stack spacing={1} sx={{ textAlign: 'center' }}>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Không có
            <Box
              component='span'
              sx={{
                mx: 0.5,
                color: 'primary.main'
                // textDecoration: 'underline'
              }}
            >
              {' '}
              dữ liệu
            </Box>
          </Typography>
        </Stack>
      </Stack>
    ),
    muiTableHeadProps: {
      sx: {
        '& .MuiTableRow-root': {
          color: (theme) => theme.palette.common.white,
          background: (theme) => theme.palette.primary.main,
          ...theme.typography.subtitle1,
          '& *': {
            fontWeight: 'inherit',
            color: (theme) => theme.palette.common.white
          }
        }
      }
    },
    localization: {
      move: 'Sắp xếp',
      rowsPerPage: 'Số lượng hiển thị:',
      of: 'trên',
      actions: ''
    }
  })

  useEffect(() => {
    dispatch(resetActivityState())
    setSkeletonAnimationDelay(true)
    closeAnimation(setSkeletonAnimationDelay)
  }, [objectId])

  useEffect(() => {
    // if the table is empty fetch the table data.
    if (status === 'idle') {
      dispatch(fetchActivityLog({ objectId, page, pageSize, rowsOnly: false }))
    }

    // when the data is fetched:
    if (status === 'success') {
      // update rows if rows are not updated
      if (rowsStatus === 'notUpdated') {
        dispatch(fetchActivityLog({ objectId, page, pageSize, rowsOnly: true }))
      }
    }
  }, [page, pageSize, status])

  closeAnimation(setSkeletonAnimationDelay)

  return (
    <Box sx={{ width: '100%', height: { xs: '600px', md: '82vh' } }}>
      <Box sx={{ height: '100%' }}>
        {status === 'loading' || status === 'idle' || skeletonAnimationDelay ? (
          <TableSkeleton />
        ) : (
          <Stack>
            <MaterialReactTable table={table} />
            <TablePagination
              component='div'
              count={config.full_result_count}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={renderRowsPerPageOptions(
                config.full_result_count
              )}
              onPageChange={(event, value) => {
                dispatch(setPage(value))
              }}
              onRowsPerPageChange={(event) => {
                dispatch(setPageSize(event.target.value))
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return 'Từ ' + from + ' đến ' + to + ' trên ' + count
              }}
              labelRowsPerPage='Số lượng hiển thị:'
            />
          </Stack>
        )}
      </Box>
    </Box>
  )
}
